$grid-gutter-width: 40px;

@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/normalize";
@import "bootstrap/print";
@import "bootstrap/scaffolding";
@import "bootstrap/grid";
@import "bootstrap/navs";
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";
@import "bootstrap/labels";
@import "slick.scss";
@import "slick-theme.scss";

// Mixins
@import "../mixins";
@import "../variables";
@import "../fonts";
@import "../common";
@import "../utils";
@import "../typography";


// Global Components
@import "../global/alert";
@import "../global/form";
@import "../global/input";
@import "../global/submit";
@import "../global/radio";
@import "../global/checkbox";
@import "../global/errors";
@import "../global/textarea";
@import "../global/cards/radio_card";
@import "../global/three_bars_btn";
@import "../global/cards/product_card";
@import "../global/cards/report_card";
@import "../global/cta";
@import "../global/plans";
@import "../global/alert";


// Layouts
@import "layouts/header";
@import "layouts/menu";
@import "../global/layouts/footer";


// Pages
@import "pages/home";
@import "pages/meetingEnd";
@import "pages/login";
@import "pages/register";
@import "pages/guard-totalwall";
