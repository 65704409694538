@media #{$mq-xs} {

	footer {
		padding: 20px 0;
		background-color: $primary;
		color: #FFF;

		.col {
			margin-top: 20px;

			&:first-child {
				margin-top: 0;
			}

			h3, p {
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			p {
				overflow: hidden;
				text-overflow: ellipsis;
				font-family: $font-raleway;

				a {
					color: #FFF;
				}
			}

			ul {
				li {
					a {
						color: #FFF;
						font-weight: normal;
						line-height: 28px;
					}
				}
			}
		}
	}

}

@media #{$mq-sm} {

	footer {
		padding: 40px 20px;

		.col {
			margin-top: 40px;
		}
	}

}

@media #{$mq-md} {

	footer {

		.col {
			margin-top: 0;
		}

	}

}


@media #{$mq-lg} {

}
