$checkboxSize: 22px;

.checkbox {
	position: relative;
	top: 6px;
	display: inline-block;
	min-width: $checkboxSize;
	min-height: $checkboxSize;
	margin: 0 10px;
	line-height: 41px;

	&:first-child {
		margin: 0;
	}

	input {
		position: absolute;
		top: 0; left: 0;
		z-index: 1;
		width: $checkboxSize;
		height: $checkboxSize;
		opacity: 0;
	}

	input + span {
		position: absolute;
		top: 0; left: 0;
		width: $checkboxSize;
		height: $checkboxSize;
		border: 1px #CFCFCF solid;
	}

	input:checked + span:before {
		content: '';
		position: absolute;
		left: 3px; top: 5px;
		width: 15px;
		height: 7px;
		border-left: 3px $primary solid;
		border-bottom: 3px $primary solid;
		@include rotate(-45);
	}
}

a.contain-checkbox{
	.checkbox {
		margin-left: 2rem;
		min-height: 19px;
		min-width: 18px;

		input {
			width: 18px;
			height: 18px;
		}

		input + span {
			width: 18px;
			height: 18px;
		}

		input:checked + span:before {
			left: 0px; top: 3px;
			border-left: 3px $white solid;
			border-bottom: 3px $white solid;
		}
	}

	label {
		font-size: 1rem;
	}
}

.checkbox_wrapper {
	display: inline-block;
	width: 100%;
	text-align: left;

	.label {
		display: inline-block;
		margin-left: 10px;
		font-size: 14px;
		line-height: 18px;

		a {
			color: $primary;
			text-decoration: none;

			&:hover {
				color: $primary;
			}
		}
	}
}

.privacy_label {
	.checkbox_wrapper {
		.checkbox {
			top: 0;
			float: left;
		}
		.label {
			width: calc(100% - 40px);
		}
	}
}


.field-checkbox {
	margin: 15px 0;

	.field_wrapper {
		line-height: 1;
		text-align: left;

		.control-label {
			display: block;
			margin-bottom: 10px;
		}
	}

	&.valign {

		&.no_label {
			margin: 35px 0 0;
		}

		.field_wrapper {
			line-height: 60px;
		}
	}

	&.tac_align {
		.field_wrapper {
			.checkbox_wrapper {
				line-height: 60px;
				text-align: center;
			}
		}
	}

	&.with-tooltip {
		.field_wrapper {
			.checkbox_wrapper {
				width: 80%;
			}
		}
	}

}


@media #{$mq-sm} {

	.checkbox {
		.label {
			margin-left: 15px;
		}
	}

}


.tgl {
	display: none;

	// add default box-sizing for this scope
	&,
	&:after,
	&:before,
	& *,
	& *:after,
	& *:before,
	& + .tgl-btn {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}

	+ .tgl-btn {
		outline: 0;
		display: block;
		width: 4em;
		height: 2em;
		position: relative;
		cursor: pointer;
		user-select: none;
		margin: 0 auto;

		&:after,
		&:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}

		&:after {
			left: 0;
		}

		&:before {
			display: none;
		}
	}

	&:checked + .tgl-btn:after {
		left: 50%;
	}
}

// themes
.tgl-light {
	+ .tgl-btn {
		background: #f0f0f0;
		border-radius: 2em;
		padding: 2px;
		transition: all .4s ease;
		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all .2s ease;
		}
	}

	&:checked + .tgl-btn {
		background: #9FD6AE;
	}
}
