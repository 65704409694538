@media #{$mq-xs} {

	.register-page {
		padding-top: 80px;

		.left_area {
			padding: 20px;
			background-image: url(/assets/media/img/backgrounds/homepage/login.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			color: #FFF;

			.centered {
				position: relative;
				z-index: 20;

				p{
					font-family: $font-raleway;

					strong{
						font-family: $font-lato;
						font-weight: normal;
					}
				}
			}

			&:after {
				content: '';
				position: absolute;
				top: 0; left: 0;
				z-index: 10;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0);
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.5)));
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
			}

			p {
				margin-top: 20px;
			}
		}

		.right_area {
			overflow: scroll;
			padding: 20px;
			text-align: center;

			h2 {
				margin-bottom: 20px;
				color: $primary;
				font-weight: bold;
			}

			#form-group-type {
				text-align: center;

				.field_wrapper {
					label {
						margin-bottom: 0;
					}
				}
			}

			.form-group {

				label{
					color: $textColor;
				}
				input{
					font-family: $font-raleway;
				}
			}

			.bottom_ctas {
				margin-top: 40px;
				color: #A2A2A2;
				font-weight: bold;

				a {
					color: #A2A2A2;
					text-decoration: underline;
				}
			}
		}

	}

}

@media #{$mq-sm} {

	.register-page {
		padding-top: 120px;
		height: 100vh;

		.left_area {
			padding: 30px;
			background-image: url(/assets/media/img/backgrounds/homepage/login.jpg);

			.centered {
				@include centered;
				width: calc(100% - 60px);
				max-width: 440px;
			}
		}

		.row,
		.col {
			height: 100%;
		}

		.right_area {
			padding: 30px;

			h2 {
				margin-bottom: 30px;
			}
		}
	}

}

@media #{$mq-md} {

	.register-page {

		.right_area {

			form {
				width: 100%;
				margin: 0 auto;
			}

		}

	}

}

@media #{$mq-lg} {


}
