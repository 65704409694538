$radioSize: 22px;

.radio {
	position: relative;
	display: inline-block;
	min-width: $radioSize;
	min-height: $radioSize;
	margin: 0 10px;
	line-height: 41px;

	&:first-child {
		margin: 0;
	}

	input {
		position: absolute;
		top: 9px;
		width: $radioSize;
		height: $radioSize;
		opacity: 0;
	}

	input + span {
		position: relative;
		top: 9px;
		float: left;
		width: $radioSize;
		height: $radioSize;
		border: 1px #CFCFCF solid;
		@include border-radius(100%);
	}


	input:checked + span:before {
		content: '';
		position: absolute;
		top: 5px; left: 5px;
		width: 10px;
		height: 10px;
		@include border-radius(100%);
		background-color: $primary;
	}

	.label {
		float: left;
		margin-left: 10px;
		font-size: 16px;
	}

}

.field-radio {
	.field_wrapper {
		line-height: 0;
	}

	&.report_frequency {
		.radio:first-child {
			margin: 0 10px;
		}
	}

}


@media #{$mq-sm} {

	.radio {
		.label {
			margin-left: 15px;
		}
	}

}