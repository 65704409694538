@media #{$mq-xs} {

	.three-bars-button {
		position: relative;
		display: block;
		width: 40px;
		height: 30px;
		margin-top: 25px;
		padding: 0;
		border: none;
		cursor: pointer;
		z-index: 100;
		font-size: 0;
		text-indent: -9999px;
		@include appearance(none);
		@include box-shadow(none);
		@include border-radius(none);

		&:focus {
			outline: none;
		}

		span {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 4px;
			background-color: $primary;
			@include background-transition(0, ease, .3);
			@include translate(-50%, -50%);

			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 4px;
				background-color: $primary;
				@include transition-duration(.3, .3);
				@include transition-delay(.3, 0);
			}
			&:before {
				top: -11px;
				@include transition-property("top, transform");
			}

			&:after {
				bottom: -11px;
				@include transition-property("bottom, transform");
			}
		}

		&.active {
			&::after {
				opacity: 0;
				transition: opacity 0s;
			}

			span {
				background-color: transparent;
				@include transition-delay(0, .3);

				&:before,
				&:after {
					background: $primary;
					@include transition-delay(0, .3);
				}
				&:before {
					top: 0;
					@include rotate(45);
				}
				&:after {
					bottom: 0;
					@include rotate(-45);
				}
			}

		}
	}

}


@media #{$mq-sm} {

	.three-bars-button {
		width: 50px;
		height: 50px;
		margin-top: 35px;
		margin-right: 20px;

		span {

			&:before {
				top: -15px;
			}

			&:after {
				bottom: -15px;
			}
		}
	}

}