@media #{$mq-xs} {

	input[type="submit"],
	button[type="submit"] {
		min-width: 100px;
	}

	.field-submit {
		margin: 10px 0;
		text-align: center;
	}

	/*form {
		.row {
			.col:first-child {
				.field-submit {
					margin-top: 0;
				}
			}
			.col:last-child {
				.field-submit {
					margin-bottom: 0;
				}
			}
		}
	}*/
}


@media #{$mq-sm} {

	.field-submit {
		margin: 20px 0;
	}

}
