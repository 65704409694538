textarea.autoExpand:focus {
	outline-style: none;
	/*outline-style: solid;
	outline-width: 2px;*/
}

@media #{$mq-xs} {
	.control-label {
		display: block;
		margin-bottom: 5px;
		//margin-left: 10px;
		margin-left: 0 !important;
		padding: 0 !important;
		color: #A2A2A2;
		font-size: 16px;
		font-weight: bold;
		line-height: 16px;
	}

	.form-group {
		//margin-top: 20px;
		margin-bottom: 15px;

		.avatar {
			float: left;
			width: 60px;
			height: 60px;
			margin-top: 20px;
			margin-right: 15px;
			@include border-radius(100%);
			background: #F1F1F1;

			& + .field_wrapper {
				float: left;
				width: calc(100% - 75px);
			}
		}

		&.field-hidden {
			margin-bottom: 0;
		}

		&:focus {
			border-color: rgba(0,0,0,.1);
			box-shadow: 0 0 8px $primary;
		}

		.btn_container {
			button {
				margin-right: 10px;
			}
		}

		.field_wrapper {
			position: relative;

			.control-label {
				display: block;
				color: #A2A2A2;
				font-size: 16px;
				font-weight: bold;
				line-height: 16px;
			}

			.field-info {
				display: block;
				margin-bottom: 5px;
				margin-left: 10px;
				color: $textColor;
				font-size: 14px;
				line-height: 14px;
			}

			.field_icon {
				@include vertically-centered;
				right: 10px; top: 50px;
				display: none;
				width: 30px;
				padding: 0;
				font-size: 30px;
			}
		}

	}

	/*form {
		.row {
			&:first-of-type {
				.col {
					.form-group {
						margin-top: 0;
					}
				}
			}
		}
	}*/
}


@media #{$mq-sm} {

	.form-group {
		.avatar {
			& + .field_wrapper {
				width: calc(100% - 100px);
			}
		}
	}

	/*form {
		.row {
			&:first-of-type {
				.col:first-child,
				.col:first-child + .col-sm-6,
				.col:first-child + .col-sm-4, .col:first-child + .col-sm-4 + .col-sm-4 {
					.form-group {
						margin-top: 0;
					}
				}
			}
		}
	}*/

}

@media #{$mq-md} {

	/*form {
		.row {
			&:first-of-type {
				.col:first-child,
				.col:first-child + .col-md-3,
				.col:first-child + .col-md-4,
				.col:first-child + .col-md-6 {
					.form-group {
						margin-top: 0;
					}
				}
			}
		}
	}*/

}

@media #{$mq-lg} {

	/*form {
		.row {
			&:first-of-type {
				.col:first-child,
				.col:first-child + .col-lg-3,
				.col:first-child + .col-lg-2,
				.col:first-child + .col-lg-4,
				.col:first-child + .col-lg-9,
				.col:first-child + .col-lg-2 + .col-lg-2,
				.col:first-child + .col-lg-3 + .col-lg-2,
				.col:first-child + .col-lg-3 + .col-lg-2 + .col-lg-2,
				.col:first-child + .col-lg-3 + .col-lg-2 + .col-lg-2 + .col-lg-3,
				.col:first-child + .col-lg-3 + .col-lg-3,
				.col:first-child + .col-lg-3 + .col-lg-3 + .col-lg-3 {
					.form-group {
						margin-top: 0;
					}
				}
			}
		}
	}*/

}
