@media #{$mq-xs} {

	.meetingEnd-page {
		padding-top: 120px;

		#thankyou{
			position: relative;
			width: 100%;
			height: calc(100vh - 120px);
			padding: 0 15px;
			background-image: url(/assets/media/img/backgrounds/meetingEnd/bg.jpg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;

			display: flex;
			align-items: center;
			justify-content: center;

			> div {
				text-align: center;
				background-color: rgba(255,255,255,.8);
				border-radius: 12px;
				padding: 32px;

				h1{
					font-size: 21px;
					padding: 0;
					margin: 0;
					line-height: 1.2;
					color: $totalwall_blu;
					font-weight: normal;
				}

				h2{
					font-size: 21px;
					padding: 6px 0 0;
					margin: 0;
					line-height: 1.2;
					color: $totalwall_red;
					font-weight: normal;
				}

				a {
					font-size: 16px;
					display: block;
					margin-top: 2.5rem;
					color: $totalwall_blu;
				}
			}
		}

	}

}


@media #{$mq-sm} {


}


@media #{$mq-md} {


}


@media #{$mq-lg} {


}
