@media #{$mq-xs} {

	.plans-container {

		&.mb_20 {
			margin-bottom: 20px;
		}

		h1.title {
			margin-bottom: 20px;
			padding: 80px 0;
			color: $primary;
			text-align: center;
		}

		&.with-bg {
			background-image: url(/assets/media/img/backgrounds/homepage/piani.jpg);
			background-repeat: no-repeat;

			h1.title {
				color: #FFF;
			}
		}

		.nav-tabs {
			border-bottom: none;

			li {
				width: 100%;
				margin-bottom: 0;

				a {
					width: 100%;
					height: 100%;
					border: 1px #CFCFCF solid;
					border-top: none;
					border-radius: 0;
					background-color: #F8F8F8;
					color: $textColor;

					p {
						margin-top: 20px;
						font-size: 12px;
						line-height: 20px;
					}
				}

				&:first-child {
					a {
						border-top: 1px #CFCFCF solid;
					}
				}

				&.active {
					a,
					a:focus,
					a:hover {
						background-color: $primary;
						border-color: $primary;
						color: #FFF;
					}
				}
			}

			&.partners {
				li {
					a {
						p {
							margin-top: 10px;
						}
					}
				}
			}
		}

	}

	.plan-description {
		margin-top: 20px;
		padding-top: 30px;
		border-top: 1px #CFCFCF solid;
		border-bottom: 1px #CFCFCF solid;
		background-color: #FFF;

		h2 {
			margin-bottom: 20px;
			color: $primary;
		}

		.plan_type {
			margin: 10px 0;

			.field-radio {
				text-align: center;

				label {
					margin-bottom: 0;
				}
			}
		}

		.radio-card {
			overflow: hidden;
			margin-top: 20px;
		}

		.plan-details {
			list-style-type: disc;
			margin: 20px 0 0 25px;
			padding-left: 20px;
			font-size: 14px;
			line-height: 24px;
		}

		.cta_container {
			height: auto;
			margin-top: 0;
			padding: 30px 0;
			line-height: 1;
			text-align: right;

			.cta {
				margin-top: 30px;

				&:first-child {
					margin-top: 0;
				}
			}
		}

		&.payment {
			margin: 20px 0;
			padding: 20px;
			border-left: 1px #CFCFCF solid;
			border-right: 1px #CFCFCF solid;

			.plan_type {
				margin: 20px 0 0;
			}

			.payment-field {
				margin: 20px 0 0;
			}

			.StripeElement {
				width: 100%;
				height: 60px;
				padding: 20px;
				border: 1px #CFCFCF solid;
				outline: none;
				background-color: transparent;
				color: #4D4D4D;
				line-height: 60px;
				text-align: left;
			}

			#card-errors {
				color: $red;
			}

			.cta_container {
				padding-top: 0;
				padding-bottom: 0;
				text-align: center;
			}
		}
	}

}

@media #{$mq-sm} {

	.plans-container {

		&.with-bg {
			/*background-image: url(/assets/media/img/piani.jpg);*/
			background-repeat: no-repeat;
			background-size: 100% auto;
		}

		h1.title {
			padding: 80px 0 20px;
			background-image: none;
		}

		.nav-tabs {
			border-bottom: none;

			li {
				width: 33.3333%;

				a {
					border: none;
					border-top: 1px #CFCFCF solid;
					border-right: 1px #CFCFCF solid;
				}

				&.active {
					a {
						border-bottom: none;
					}
				}

				&:first-child {
					a {
						border-left: 1px #CFCFCF solid;
					}
				}
			}

			&.partners {
				li {
					width: 25%;

					a {
						p {
							margin-top: 20px;
						}
					}
				}
			}
		}

	}

	.plan-description {
		margin-top: 0;
		padding-left: 40px;
		padding-right: 40px;
		border-left: 1px #CFCFCF solid;
		border-right: 1px #CFCFCF solid;

		.plan_type {
			margin: 20px 0 0;
		}

		.radio-card {
			margin-top: 40px;
		}

		.cta_container {

			.cta {
				margin: 0 0 0 15px;

				&:first-child {
					margin-left: 0;
				}
			}
		}

		&.payment {
			.radio-card {
				margin-top: 20px;
			}
		}
	}

}

@media #{$mq-md} {

	.plans-container {

		.nav-tabs {
			li {
				a {
					padding: 20px;
				}
			}
		}

	}

	.plan-description {
		padding-bottom: 40px;

		h2 {
			margin-bottom: 0;
		}

		p {
			margin-top: 40px;
		}

		.cta_container {
			padding: 0 20px;

		}

	}

}


@media #{$mq-lg} {

	.plans-container {

		.nav-tabs {
			li {
				position: relative;
				width: 16.6666%;
				height: 170px;

				a {
					position: absolute;
					bottom: 0;
					height: 90%;

					h3 {
						font-size: 18px;
						line-height: 28px;
					}
				}

				&.active {
					a {
						height: 100%;
					}
				}
			}
		}

	}



}
