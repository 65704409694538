@media #{$mq-xs} {

	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		font-weight: bold;

		i {
			font-weight: normal;
		}
	}

	h1 {
		font-size: 30px;
		line-height: 42px;
	}

	h2 {
		font-size: 20px;
		line-height: 30px;
	}

	h3 {
		font-size: 18px;
		line-height: 28px;
	}

	p {
		font-size: 12px;
		font-weight: normal;
		line-height: 20px;
	}

}


@media #{$mq-sm} {


}


@media #{$mq-md} {


}


@media #{$mq-lg} {

	h1 {
		font-size: 50px;
		line-height: 72px;
	}

	h2 {
		font-size: 24px;
		line-height: 34px;
	}

	h3 {
		font-size: 16px;
		line-height: 24px;
	}
}

