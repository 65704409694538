@media #{$mq-xs} {

	.cta {
		display: inline-block;
		height: 42px;
		padding: 0 20px;
		border: none;
		background-color: $buttonBackgroundColor;
		color: $buttonColor;
		font-size: 12px;
		line-height: 44px;
		@include all-transition(.3);

		&:hover,
		&:focus,
		&:active {
			color: $buttonColor;
			text-decoration: none;
			background-color: $buttonBackgroundColorHover;
			outline: none;
		}

		&.cta-block{
			display: block;
			width: 100%;
		}

		&.orange {
			background-color: $orange;

			&:hover {
				background-color: lighten($orange, 10%);
			}
		}

		&.red {
			background-color: $red;

			&:hover {
				background-color: lighten($red, 10%);
			}
		}

		&.green {
			color: $buttonGreenColor;
			background-color: $buttonGreenBackgroundColor;

			&:hover {
				background-color: $buttonGreenBackgroundColorHover;
			}
		}

		&.with-icon {
			text-indent: -5px;

			i {
				position: relative;
				top: -1px;
				margin-right: 10px;
				font-size: 16px;
				vertical-align: middle;
			}

		}

		&.cta-primary {
			min-width: 100px;
			text-align: center;
			color: $buttonColor;
			background-color: $buttonBackgroundColor;

			&:hover,
			&:focus,
			&:active {
				background-color: $buttonBackgroundColorHover;
			}
		}

		&.cta-secondary {
			width: 100%;
			height: auto;
			padding: 0;
			background-color: $buttonSecondaryBackgroundColor;
			color: $buttonSecondaryColor;
			font-weight: bold;
			line-height: 18px;
			text-align: center;

			&:hover,
			&:focus,
			&:active {
				background-color: $buttonSecondaryBackgroundColorHover;
			}
			/*&.active {
				color: $primary;
			}*/
		}

		&[disabled],
		&.disabled {
			opacity: .5;
			color: $buttonDisabledColor;
			background-color: $buttonDisabledBackgroundColor;

			&:hover,
			&:focus,
			&:active {
				background-color: $buttonDisabledBackgroundColor;
			}

			&.green {
				&:hover,
				&:focus,
				&:active {
					background-color: $buttonDisabledBackgroundColor;
				}
			}

			&.orange {
				&:hover,
				&:focus,
				&:active {
					background-color: $buttonDisabledBackgroundColor;
				}
			}
		}
	}

	.cta_container {
		text-align: center;

		&.tar {
			text-align: right;
		}

		&.tal {
			text-align: left;
		}

		&.no_mt {
			margin-top: 0;
		}

		&.no_label {
			margin-top: 26px;
		}

		&.mb {
			margin-bottom: 26px;
		}
	}
}


@media #{$mq-md} {

	.cta_container {
		margin-top: 30px;
	}

}
