@media #{$mq-xs} {

	textarea {
		width: 100%;
		/*height: 150px;*/
		resize: none;
		padding: 10px;
		border: 1px $inputBorderColor solid;
		@include border-radius(4px);

		&:focus {
			outline: none;
		}
	}

	.terms_conditions_text,
	.privacy_text {
		width: 100%;
		padding: 10px;
		font-size: 10px;

		& ~ .form-group {
			opacity: .5;
		}

		&.scrolled ~ .form-group {
			opacity: 1;
		}
	}

}

