.flex {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	&.fullpage {
		flex:1;
		height: 100%;
	}

	&.cols {
		flex-direction: row;

		.half{
			width: calc(50% - 50px);
			height: calc(100% - 40px);
		}

		.megabutton {
			margin: 0 12px;

			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	&.vertical-centered{
		align-items: center;
	}

	&.horizontal-centered{
		justify-content: center;
	}
}

@media #{$mq-xs} {

	.login-page {
		padding-top: 80px;

		.left_area {
			padding: 20px;
			background-image: url(/assets/media/img/backgrounds/homepage/login.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			color: #FFF;

			.centered {
				position: relative;
				z-index: 20;

				p{
					font-family: $font-raleway;

					strong{
						font-family: $font-lato;
						font-weight: normal;
					}
				}
			}

			&:after {
				content: '';
				position: absolute;
				top: 0; left: 0;
				z-index: 10;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0);
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.5)));
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
			}

			p {
				margin-top: 20px;
			}
		}

		.right_area {
			overflow: auto;
			padding: 20px;
			text-align: center;

			h2 {
				margin-bottom: 20px;
				color: $primary;
				font-weight: bold;
			}

			.or {
				position: relative;
				margin: 20px 0;
				font-size: 16px;
				font-weight: bold;
				font-style: italic;

				span {
					position: relative;
					z-index: 10;
					padding-left: 20px;
					padding-right: 20px;
					background-color: #FFF;
					color: $textColor;
				}

				&:before {
					content: '';
					position: absolute;
					top: 50%; left: 0;
					width: 100%;
					height: 1px;
					background-color: #D8D8D8;
				}
			}

			.form-group  {

				label{
					color: $textColor;
				}
				input{
					font-family: $font-raleway;
				}
			}


			.bottom_ctas {
				margin-top: 40px;
				font-family: $font-raleway;
				color: $textColorMedium;
				font-weight: bold;

				a {
					color: $textColorMedium;
					text-decoration: underline;
				}
			}
		}

	}

}

@media #{$mq-sm} {

	.login-page {
		padding-top: 120px;
		height: 100vh;

		.left_area {
			padding: 30px;
			/*background-image: url(/assets/media/img/piani-sm.jpg);*/

			.centered {
				@include centered;
				width: calc(100% - 60px);
				max-width: 440px;
			}
		}

		.row,
		.col {
			height: 100%;
		}

		.right_area {
			padding: 30px;

			h2 {
				margin-bottom: 30px;
			}

			.or {
				margin: 30px 0;
			}
		}
	}

}

@media #{$mq-md} {

	.login-page {

		.right_area {

			.or {
				width: 70%;
				margin: 30px auto;
			}

			form {
				width: 70%;
				margin: 0 auto;
			}

		}

	}

}

@media #{$mq-lg} {

	.login-page {


	}

}
