@media #{$mq-xs} {

	header {
		position: fixed;
		top: 0; left: 0;
		z-index: 100;
		width: 100%;
		height: 80px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
		border-bottom: 1px solid $headerBottomBorderColor;
		background-color: $headerBackgroundColor;

		.logo {
			font-size: 14px;
			font-weight: bold;
			line-height: 80px;

			@include all-transition(.3);

			i {
				font-weight: normal;
			}

			&:active,
			&:focus,
			&:hover {
				color: darken($primary, 10%);
				text-decoration: none;
				outline: none;
			}

			img {
                max-width: 60%;
                max-height: 80px;
			}
		}
	}

}



@media #{$mq-sm} {

	header {
		height: 120px;

		.logo {
			margin-left: 20px;
			font-size: 24px;
			line-height: 120px;
		}
	}

}

@media #{$mq-md} {

	header {

		.login_link {
			margin-right: 35px;
			color: $textColor;
			line-height: 120px;
			@include all-transition(.3);

			&:active,
			&:focus,
			&:hover {
				color: darken($primary, 10%);
				text-decoration: none;
				outline: none;
			}
		}

	}

}

