.guard-totalwall {
    min-height: 80vh;
    background-color: #F4F7FB;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
        background-color: #fff;
        width: 410px;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(179, 179, 179, 0.24);
        padding: 30px;
        margin: 30px;

        h3, p {
            margin-bottom: .6rem;
        }

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 15px;
        }

        input {
            font-size: 14px;
            text-align: left;
            height: 35px;
            line-height: 35px;
            border-radius: 6px;
            padding: 0 10px;

            &:focus {
                border: 1px rgba(65, 107, 169, 0.75) solid;
                box-shadow: 0 0 2px 0 rgba(65, 107, 169, 0.75);
            }
        }

        button {
            color: #fff;
            background-color: #4062ee;
            line-height: 35px;
            border-radius: 6px;
            display: inline-block;
            padding: 0 15px;
            font-size: 12px;
            box-shadow: none;
            outline: none;
            text-decoration: none;
            border: unset;

            &:hover, &:focus {
                transform: scale(1.1);
            }
        }
    }
}
