@media #{$mq-xs} {

	.card {
		&.product-card {
			position: relative;
			height: 380px;
			padding: 20px;
			background-size: cover;
			background-position: center;

			&:after {
				content: '';
				position: absolute;
				top: 0; left: 0;
				z-index: 10;
				width: 100%;
				height: 100%;
				background: -moz-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
				background: -webkit-linear-gradient(top, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#00000000',GradientType=0 );
			}

			h3 {
				position: absolute;
				z-index: 15;
				margin-bottom: 35px;
				color: #FFF;
			}

			p {
				font-family: $font-raleway;
				position: absolute;
				top: 100px;
				z-index: 15;
				width: calc(100% - 60px);
				color: #FFF;
			}

			.cta {
				position: absolute;
				bottom: 20px; left: 20px;
				z-index: 15;
			}


			&.active-brand {
				background-image: url(/assets/media/img/backgrounds/servizi/ab24.jpg);
			}

			&.social-finder {
				background-image: url(/assets/media/img/backgrounds/servizi/sf.jpg);
			}

			&.social-performance {
				background-image: url(/assets/media/img/backgrounds/servizi/sp.jpg);
			}

			&.total-wall {
				background-image: url(/assets/media/img/backgrounds/servizi/tw.jpg);
			}

			&.photostream {
				background-image: url(/assets/media/img/backgrounds/servizi/ps.jpg);
			}

			&.social-wall {
				background-image: url(/assets/media/img/backgrounds/servizi/sw.jpg);
			}

		}
	}

}


@media #{$mq-sm} {


}
