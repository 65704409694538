@media #{$mq-xs} {

	.menu {
		position: fixed;
		top: 50px; left: -100%;
		z-index: 50;
		width: 100%;
		height: 100%;
		background-color: #FFF;
		@include all-transition(.5, ease-out);

		&.slide-in {
			left: 0;
		}

		nav {
			position: relative;
			padding: 0;
			height: calc(100vh - 50px);
			overflow: auto;

			ul {
				@include centered;
				width: 80%;
				text-align: center;

				li {
					a {
						display: block;
						padding: 18px 0;
						color: $textColor;
						font-size: 14px;
						line-height: 14px;

						@include all-transition(.3);

						&:focus,
						&:active,
						&:hover {
							color: $primary;
							text-decoration: none;
							outline: none;
						}
					}

					&:first-child {
						a {
							padding-top: 0;
						}
					}

					&:last-child {
						a {
							padding-bottom: 0;
						}
					}
				}
			}

		}

	}


}

@media #{$mq-sm} {

	.menu {
		top: 120px;

		nav {
			height: calc(100vh - 120px);

			ul {
				li {
					a {
						padding: 20px 0;
						font-size: 28px;
						line-height: 28px;
					}
				}
			}
		}
	}

}

@media #{$mq-md} {

	.menu {
		position: relative;
		top: auto; left: auto;
		z-index: auto;
		float: left;
		width: 50%;
		padding-left: 0;
		padding-right: 0;

		nav {
			height: 120px;

			ul {
				position: relative;
				top: auto; left: auto;
				width: 100%;
				transform: none;

				li {
					display: inline-block;
					margin: 0 15px;

					a {
						display: inline;
						font-size: 14px;
						line-height: 120px;
					}
				}
			}
		}
	}


}