.min-width-unset {
  min-width: unset !important;
}
.fr {
	float: right;
}
.tal {
	text-align: left;
}
.tac {
	text-align: center;
}
.tar {
	text-align: right;
}
.mt-5 {
	margin-top: 5px;
}
.mb-5 {
	margin-bottom: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mt-20 {
	margin-top: 20px;
}
.mb-20 {
	margin-bottom: 20px;
}
.row.row-eq-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display:         flex;
}
.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
