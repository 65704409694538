html, body {
	overflow-x: hidden !important;
}

body {
	font-family: $font-family-sans-serif;
	background-color: var(--custom-bg-color, $bodyBg) !important;

	color: $textColor;
}

button:focus,
input:focus,
select:focus {
	outline: none;
}

strong {
	font-weight: bold;
}

a {
	color: $primary;
}


ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

div.jqcloud span.vertical {
	-webkit-writing-mode: vertical-rl;
	writing-mode: tb-rl;
}

.container-fluid-no-padding {
	padding-left: 0;
	padding-right: 0;
}

.primary {
	color: $primary;
}

.blue {
	color: $blue;
}

.green {
	color: $green;
}

.red {
	color: $red;
}

.twitter-color {
	color: #1DA1F3;
}

.facebook-color {
	color: #3B5999;
}

.instagram-color {
	color: #E56969;
}

.youtube-color {
	color: #1DA1F3;
}

.to_upper_case {
	text-transform: uppercase;
}

.col-no-pl {
	padding-left: 0;
}

.col-no-pr {
	padding-right: 0;
}

@media #{$mq-sm} {

	.container {
		width: 740px;
	}

}

@media #{$mq-md} {

	.container {
		width: 980px;
	}

	.col-md-2_5 {
		width: 20%;
	}

}


@media #{$mq-lg} {

	.container {
		width: 1180px;
	}

}


@media #{$mq-xlg} {

	.col-xlg-2 {
		width: 16.6666666667%;
	}

	.col-xlg-3 {
		width: 25%;
	}

	.col-xlg-4 {
		width: 33.3333333333%;
	}

	.col-xlg-5 {
		width: 41.6666666667%;
	}

	.col-xlg-6 {
		width: 50%;
	}

	.col-xlg-7 {
		width: 58.3333333333%;
	}

	.col-xlg-8 {
		width: 66.6666666667%;
	}

	.col-xlg-9 {
		width: 75%;
	}

	.col-xlg-10 {
		width: 83.3333333333%;
	}

	.col-xlg-11 {
		width: 91.6666666667%;
	}

	.col-xlg-12 {
		width: 100%;
	}

	.col-xlg-push-2 {
		left: 16.6666666667%;
	}

	.hidden-xlg {
		display: none !important;
	}

}

