@media #{$mq-xs} {

	.home-page {
		padding-top: 80px;

		#cover-slider-wrapper{
			position: relative;

			h1 {
				position: absolute;
				left: 10px; bottom: 20px;
				/*max-width: 170px;*/
				color: #FFF;
				font-weight: bold;
				line-height: 1.2;
				z-index: 1;

				i {
					font-weight: normal;
				}
			}
		}

		.cover {
			position: relative;
			width: 100%;
			height: 80vh;
			padding: 0 15px;
			background-image: url(/assets/media/img/backgrounds/homepage/app_totalwall.jpg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;

			/*.container {
				position: relative;
				height: 100%;
			}*/

			h1{
				display: none;
			}
		}

		.discover_more {
			position: absolute;
			right: -50px; bottom: 50px;
			padding: 0 20px;
			width: 150px;
			height: 50px;
			background-color: $buttonBackgroundColor;
			color: $buttonColor;
			font-size: 10px;
			font-weight: bold;
			line-height: 50px;
			text-align: center;
			@include rotate(90);
			@include all-transition(.3);

			img {
				width: 30px;
				margin-left: 10px;
			}

			&:hover {
				text-decoration: none;
				background-color: $buttonBackgroundColorHover;
			}
		}

		.content {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.focus {
			margin: 20px 0;

			.focus-point {
				padding-top: 20px;
				padding-bottom: 20px;
				text-align: center;

				img {
					width: 80px;
				}

				h3 {
					margin: 30px 0 15px;
				}
			}
		}

		.people-container {
			background-image: url(/assets/media/img/backgrounds/homepage/2.jpg);
			background-size: cover;
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				height: 100%;
				background-image: linear-gradient(to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8));
			}

			.description {

				h3 {
					padding: 80px 20px 20px;
					color: #FFF;
					text-align: center;
				}
			}

			.focus {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.product-container {

			h1 {
				margin: 20px 0 40px;
				text-align: center;
			}

			.product-card {
				margin-bottom: 20px;
			}
		}

		.report-container {

			p {
				color: $textColorMedium;

				a {
					color: $blue;
					font-family: $font-raleway;
				}
			}
		}

		.faqs {
			padding: 20px;

			h1 {
				margin: 20px 0 40px;
				color: $primary;
			}

			.faq {
				margin-top: 20px;

				h2 {
					margin: 20px 0;
					color: $primary;
				}
				p {
					margin-bottom: 20px;
					color: $textColorMedium;

					&:last-child {
						margin-bottom: 0;
					}
				}
				a {
					color: $blue;
					font-family: $font-raleway;
				}
			}
		}

	}

}


@media #{$mq-sm} {

	.home-page {
		padding-top: 120px;

		.cover {
			height: 334px;
			padding: 0;
			/*background-image: url(/assets/media/img/cover-sm.jpg);*/

			h1 {
				left: 50px; bottom: 50px;
				max-width: 100%;
			}
		}

		.content {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		.focus {
			margin: 50px 0;
		}

		.people-container {
			/*background-image: url(/assets/media/img/home-people-sm.jpg);*/
			background-size: cover;
			background-position: 90%;
			padding-bottom: 50px;

			.description {
				background: none;

				h3 {
					padding: 80px 50px 20px;
				}
			}

			.focus {
				margin-bottom: 0;
			}

			.focus-point {
				h3, p {
					color: #FFF;
				}
			}
		}

		.product-container {
			width: 740px;

			h1 {
				margin: 40px 0;
			}

			.product-card {
				margin-bottom: 40px;
			}

		}

		.faqs {
			padding: 30px 20px;

			h1 {
				margin-bottom: 0;
			}
		}

	}

}


@media #{$mq-md} {

	.home-page {

		.cover {
			height: 445px;
			/*background-image: url(/assets/media/img/cover-md.jpg);*/

			h1 {
				left: 50px;
			}


		}

		.discover_more {
			right: -70px; bottom: 70px;
			width: 190px;
			font-size: 14px;
			line-height: 48px;

			img {
				width: 40px;
				margin-left: 10px;
			}
		}

		.content {
			width: 80%;
			margin: 0 auto;
		}

		.people-container {
			/*background-image: url(/assets/media/img/home-people-md.jpg);*/
			background-position: center top;

			.description {
				padding-left: 80px;
				padding-right: 80px;

				h3 {
					padding: 80px 100px 20px;
				}
			}
		}
	}

}


@media #{$mq-lg} {

	.home-page {

		.cover {
			height: calc(100vh - 120px);
			/*background-image: url(/assets/media/img/cover.jpg);*/

			h1 {
				left: 50px;
			}
		}

		.discover_more {
			right: -85px; bottom: 85px;
			width: 270px;
			height: 100px;
			font-size: 20px;
			line-height: 100px;

			img {
				width: 50px;
				margin-left: 15px;
			}
		}

		.content {
			width: 80%;
			margin: 0 auto;
		}

		.people-container {
			/*background-image: url(/assets/media/img/home-people-md.jpg);*/
			background-position: center top;

			.description {
				padding-left: 80px;
				padding-right: 80px;

				h3 {
					padding: 80px 100px 20px;
				}
			}
		}

		.product-container {
			width: 1110px;

			h1 {
				margin: 70px 0;
			}
		}

		.faqs {
			padding: 50px 20px;
		}

	}

}
