@media #{$mq-xs} {

	.has-float-label label {
		padding: 0 10px;
		pointer-events: none;
	}
	.has-float-label .form-control:placeholder-shown:not(:focus)+label {
		top: .65em;
	}
	.has-float-label .form-control:placeholder-shown:focus+label {
		color: #1e5a9a;
	}
	.has-float-label label::after {
		height: 3px;
	}

	input {
		width: 100%;
		height: 50px;
		padding: 0 20px;
		border: 1px $inputBorderColor solid;
		outline: none;
		background-color: transparent;
		color: $textColor;
		line-height: 50px;
		text-align: left;
		font-family: $font-raleway;
		@include border-radius(4px);

		&.no-border {
			border: none
		}

		&.input-same-height-cta {
			height: 42px;
		}

		&:focus{
			border: 2px #1e5a9a solid;
		}

		&.backgroundselect{
			background-color: #fff;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg enable-background='new 0 0 185.343 185.343' version='1.1' viewBox='0 0 185.34 185.34' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m51.707 185.34c-2.741 0-5.493-1.044-7.593-3.149-4.194-4.194-4.194-10.981 0-15.175l74.352-74.347-74.352-74.352c-4.194-4.194-4.194-10.987 0-15.175 4.194-4.194 10.987-4.194 15.18 0l81.934 81.934c4.194 4.194 4.194 10.987 0 15.175l-81.934 81.939c-2.093 2.1-4.84 3.15-7.587 3.15z' fill='%23999999'/%3e%3c/svg%3e");
			background-repeat: no-repeat, repeat;
			background-position: right .7em top 50%, 0 0;
			background-size: .65em auto, 100%;

			width: 100%;
			position: relative;
			display: inline-block;
			zoom: 1;
		}
	}

	input[type="file"] {
		padding-top: 19px;
		padding-bottom: 19px;
		line-height: 10px;
	}

	input::placeholder {
		color: $placeholderColor;
		opacity: 1;
	}

	input:-ms-input-placeholder {
		color: $placeholderColor;
	}

	input::-ms-input-placeholder {
		color: $placeholderColor;
	}

	.field-date_with_reset,
	.field-date,
	.field-text,
	.field-email,
	.field-password,
	.field-radio,
	.field-select,
    .field-multiselect,
	.field-file {
		text-align: left;

		label {
			display: inline-block;
			margin-bottom: 5px;
			padding-left: 10px;
			color: $labelColor;
			font-weight: bold;
		}
	}

}


@media #{$mq-sm} {

	input {
		font-size: 14px;

		&.input-same-height-cta {
			height: 42px;
		}
	}

}

