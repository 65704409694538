@media #{$mq-xs} {

	.alert {
		position: relative;
		width: 100%;
		margin: 10px auto;
		padding: 20px;
		border: none;
		color: #FFF;
		font-size: 16px;
		line-height: 16px;

		&.alert-dismissible {
			.close {
				position: absolute;
				top: 50%; right: 15px;
				display: block;
				height: 30px;
				margin-top: -15px;
				padding: 0;
				border: none;
				background: none;
				font-size: 30px;
				line-height: 36px;
			}
		}

		&.alert-danger {
			background-color: rgba(230, 0, 0, .5);
		}

		&.alert-success {
			border: 1px #3C763D solid;
			color: #3C763D;
		}

		&.alert-info {
			color: #23527c;
		}
		&.alert-warning {
			color: #8a6d3b;
		}

	}

}

@media #{$mq-sm} {

}

@media #{$mq-md} {


}

