@media #{$mq-xs} {

	.radio-card {
		display: inline-block;
		width: 100%;
		padding: 20px;
		border: 1px #CFCFCF solid;

		.form-group {
			margin-top: 0;

			label {
				width: 100%;
				padding-left: 0;

				.label {
					line-height: 20px;
				}
			}
		}

		&.active,
		&:active,
		&:focus {
			border-color: $primary;
		}

		h3, small {
			margin-left: 30px;
		}

		h3 {
			margin-bottom: 5px;
		}

		small {
			display: block;
			font-size: 12px;
		}

		.cta {
			margin-top: 20px;
			margin-left: 30px;
		}

		&.single-subscription {
			margin-top: 20px;
		}
	}

}


@media #{$mq-sm} {

	.radio-card {
		padding: 20px 30px;

		h3, small {
			margin-left: 35px;
		}
	}

}

@media #{$mq-sm} {

	.radio-card {
		height: 160px;

		.cta {
			margin-top: 15px;
		}
	}

}