// MEDIA QUERIES
$mq-minw-xs: 1px;
$mq-minw-sm: 768px;
$mq-minw-md: 992px;
$mq-minw-lg: 1200px;
$mq-minw-xlg: 1440px;
$mq-xs: "only screen and (min-width:" + $mq-minw-xs + ")";
$mq-sm: "only screen and (min-width:" + $mq-minw-sm + ")";
$mq-md: "only screen and (min-width:" + $mq-minw-md + ")";
$mq-lg: "only screen and (min-width:" + $mq-minw-lg + ")";
$mq-xlg: "only screen and (min-width:" + $mq-minw-xlg + ")";

$totalwall_red: #FD495C;
$totalwall_blu: #416BA9;

// BOOTSTRAP COLORS
$brand-primary: #3097D1;
$brand-info:    #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

$event:     #E5446D;
$internal:  #FFA500;
$demo:      #1E90FF;

// FONTS
//$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-sans-serif: 'Lato', sans-serif;
$font-lato: 'Lato', sans-serif;
$font-raleway: 'Raleway', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;

// MAIN COLORS
$bodyBg:          #FFF;
$bodyBgDark:      #F5F5F5;
$textColor:       rgba(0,0,0,.87);
$textColorMedium: rgba(0,0,0,.6);
$textColorLight:  rgba(0,0,0,.38);
$primary:         #416BA9;
$orange:          #FFD166;
$green:           #019E75;/*#06D6A0;*/
$red:             #E3153B;/*#FD495C;*/
$blue:            #344E89;
$white:           #FFF;

// HEADER COLORS
$headerBottomBorderColor: #F2F2F2;
$headerBackgroundColor:   #FFF;


// MENU COLORS
$menuBackgroundColor:      $white;
$menuItemBackgroundColor:  #C2DFF4;
/*$menuItemBorderHoverColor: #C2DFF4;*/
$menuTextColor:            $textColor;
/*$menuSearchColor:          $textColor;*/


// INPUT COLORS
$inputBorderColor: #CFCFCF;
$placeholderColor: #A2A2A2;
$labelColor:       #A2A2A2;


// BUTTONS AND PAGINATIONS
$buttonColor:                #FFF;
$buttonBackgroundColor:      #344E89;
$buttonBackgroundColorHover: #497CBB;

$buttonGreenColor:                #FFF;
$buttonGreenBackgroundColor:      #019E75;
$buttonGreenBackgroundColorHover: #05C391;

$buttonSecondaryColor:                #292929;
$buttonSecondaryBackgroundColor:      transparent;/*#F6F6F9;*/
$buttonSecondaryBackgroundColorHover: transparent;/*#F6F6F9;*/

$buttonDisabledColor:                #81818A;
$buttonDisabledBackgroundColor:      #CCCED0;
$buttonDisabledBackgroundColorHover: #CCCED0;

